import { default as loginikXFF2hTKwMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as registrationOpgP1y95a5Meta } from "/app/pages/auth/registration.vue?macro=true";
import { default as contractorscgluBP6UA2Meta } from "/app/pages/contractors.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91line_93wDE3uGOqZMMeta } from "/app/pages/products/[line].vue?macro=true";
import { default as index18IXo8oN8QMeta } from "/app/pages/products/index.vue?macro=true";
import { default as products08Bd1RJ93nMeta } from "/app/pages/products.vue?macro=true";
import { default as indexaeouN7Kzh7Meta } from "/app/pages/profile/index.vue?macro=true";
import { default as subscriptionsoIX9jYPDmAMeta } from "/app/pages/profile/subscriptions.vue?macro=true";
import { default as profileupBlgdy3vLMeta } from "/app/pages/profile.vue?macro=true";
import { default as sSXKnT5H80bMeta } from "/app/pages/s.vue?macro=true";
import { default as tXU8oQDH0gcMeta } from "/app/pages/t.vue?macro=true";
import { default as ui_45kitxRnkS5jbEfMeta } from "/app/pages/ui-kit.vue?macro=true";
export default [
  {
    name: loginikXFF2hTKwMeta?.name ?? "auth-login",
    path: loginikXFF2hTKwMeta?.path ?? "/auth/login",
    meta: loginikXFF2hTKwMeta || {},
    alias: loginikXFF2hTKwMeta?.alias || [],
    redirect: loginikXFF2hTKwMeta?.redirect,
    component: () => import("/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: registrationOpgP1y95a5Meta?.name ?? "auth-registration",
    path: registrationOpgP1y95a5Meta?.path ?? "/auth/registration",
    meta: registrationOpgP1y95a5Meta || {},
    alias: registrationOpgP1y95a5Meta?.alias || [],
    redirect: registrationOpgP1y95a5Meta?.redirect,
    component: () => import("/app/pages/auth/registration.vue").then(m => m.default || m)
  },
  {
    name: contractorscgluBP6UA2Meta?.name ?? "contractors",
    path: contractorscgluBP6UA2Meta?.path ?? "/contractors",
    meta: contractorscgluBP6UA2Meta || {},
    alias: contractorscgluBP6UA2Meta?.alias || [],
    redirect: contractorscgluBP6UA2Meta?.redirect,
    component: () => import("/app/pages/contractors.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: products08Bd1RJ93nMeta?.name ?? undefined,
    path: products08Bd1RJ93nMeta?.path ?? "/products",
    meta: products08Bd1RJ93nMeta || {},
    alias: products08Bd1RJ93nMeta?.alias || [],
    redirect: products08Bd1RJ93nMeta?.redirect,
    component: () => import("/app/pages/products.vue").then(m => m.default || m),
    children: [
  {
    name: _91line_93wDE3uGOqZMMeta?.name ?? "products-line",
    path: _91line_93wDE3uGOqZMMeta?.path ?? ":line()",
    meta: _91line_93wDE3uGOqZMMeta || {},
    alias: _91line_93wDE3uGOqZMMeta?.alias || [],
    redirect: _91line_93wDE3uGOqZMMeta?.redirect,
    component: () => import("/app/pages/products/[line].vue").then(m => m.default || m)
  },
  {
    name: index18IXo8oN8QMeta?.name ?? "products",
    path: index18IXo8oN8QMeta?.path ?? "",
    meta: index18IXo8oN8QMeta || {},
    alias: index18IXo8oN8QMeta?.alias || [],
    redirect: index18IXo8oN8QMeta?.redirect,
    component: () => import("/app/pages/products/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profileupBlgdy3vLMeta?.name ?? undefined,
    path: profileupBlgdy3vLMeta?.path ?? "/profile",
    meta: profileupBlgdy3vLMeta || {},
    alias: profileupBlgdy3vLMeta?.alias || [],
    redirect: profileupBlgdy3vLMeta?.redirect,
    component: () => import("/app/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: indexaeouN7Kzh7Meta?.name ?? "profile",
    path: indexaeouN7Kzh7Meta?.path ?? "",
    meta: indexaeouN7Kzh7Meta || {},
    alias: indexaeouN7Kzh7Meta?.alias || [],
    redirect: indexaeouN7Kzh7Meta?.redirect,
    component: () => import("/app/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: subscriptionsoIX9jYPDmAMeta?.name ?? "profile-subscriptions",
    path: subscriptionsoIX9jYPDmAMeta?.path ?? "subscriptions",
    meta: subscriptionsoIX9jYPDmAMeta || {},
    alias: subscriptionsoIX9jYPDmAMeta?.alias || [],
    redirect: subscriptionsoIX9jYPDmAMeta?.redirect,
    component: () => import("/app/pages/profile/subscriptions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: sSXKnT5H80bMeta?.name ?? "s",
    path: sSXKnT5H80bMeta?.path ?? "/s",
    meta: sSXKnT5H80bMeta || {},
    alias: sSXKnT5H80bMeta?.alias || [],
    redirect: sSXKnT5H80bMeta?.redirect,
    component: () => import("/app/pages/s.vue").then(m => m.default || m)
  },
  {
    name: tXU8oQDH0gcMeta?.name ?? "t",
    path: tXU8oQDH0gcMeta?.path ?? "/t",
    meta: tXU8oQDH0gcMeta || {},
    alias: tXU8oQDH0gcMeta?.alias || [],
    redirect: tXU8oQDH0gcMeta?.redirect,
    component: () => import("/app/pages/t.vue").then(m => m.default || m)
  },
  {
    name: ui_45kitxRnkS5jbEfMeta?.name ?? "ui-kit",
    path: ui_45kitxRnkS5jbEfMeta?.path ?? "/ui-kit",
    meta: ui_45kitxRnkS5jbEfMeta || {},
    alias: ui_45kitxRnkS5jbEfMeta?.alias || [],
    redirect: ui_45kitxRnkS5jbEfMeta?.redirect,
    component: () => import("/app/pages/ui-kit.vue").then(m => m.default || m)
  }
]